import PaginatedGridGallery_PaginatedGridDefaultSkinComponent from '../components/NativeGalleries/PaginatedGridGallery/viewer/skinComps/PaginatedGridDefaultSkin/PaginatedGridDefaultSkin.skin';
import PaginatedGridGallery_PaginatedGridDefaultSkinController from '../components/NativeGalleries/PaginatedGridGallery/viewer/PaginatedGridGallery.controller';


const PaginatedGridGallery_PaginatedGridDefaultSkin = {
  component: PaginatedGridGallery_PaginatedGridDefaultSkinComponent,
  controller: PaginatedGridGallery_PaginatedGridDefaultSkinController
};


export const components = {
  ['PaginatedGridGallery_PaginatedGridDefaultSkin']: PaginatedGridGallery_PaginatedGridDefaultSkin
};

