import * as React from 'react';
import { TestIds as ItemTestIds } from '../../commons/constants';
import type { PanelProps } from '../PaginatedGridGallery.types';

export const Panel: React.FC<PanelProps> = ({
  title,
  description,
  skinsStyle,
}) => (
  <>
    {!!title && (
      <div data-testid={ItemTestIds.title} className={skinsStyle.title}>
        {title}
      </div>
    )}
    {!!description && (
      <p
        data-testid={ItemTestIds.description}
        className={skinsStyle.description}
      >
        {description}
      </p>
    )}
  </>
);
