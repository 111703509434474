import { TestIds as commonTestIds } from '../commons/constants';

export const TestIds = {
  itemsContainer: 'paginated-grid-gallery-items-container',
  playButton: 'paginated-grid-gallery-playButton',
  playButtonInner: 'paginated-grid-gallery-playButtonInner',
  navButtons: commonTestIds.navButtons,
  prevButton: commonTestIds.prevButton,
  nextButton: commonTestIds.nextButton,
  nextButtonInner: commonTestIds.nextButtonInner,
  prevButtonInner: commonTestIds.prevButtonInner,
  counter: commonTestIds.counter,
} as const;

export const translations = {
  ARIA_LABEL_NAMESPACE: 'ariaLabels',
  PAGINATED_GRID_GALLERY_ARIA_LABEL_KEY: 'PaginatedGrid_AriaLabel_TopLevel',
  PAGINATED_GRID_GALLERY_ARIA_LABEL_DEFAULT: 'Paginated grid gallery',
  PLAY_BUTTON_ARIA_LABEL_KEY: 'Gallery_AriaLabel_PlayButton',
  PLAY_BUTTON_ARIA_LABEL_DEFAULT: 'play',
};
